import axios from "axios";
import * as ActionType from "./agency.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";
export const loginAgency = (login) => (dispatch) => {

  axios
    .post("admin/agency/login", login)
    .then((res) => {
     
      if (res.data.status) {
        dispatch({ type: ActionType.LOGIN_AGENCY, payload: res.data.token });
        setToast("success", "Login Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {

      
      setToast("error", error);

    });
};

export const getProfile = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/agency/profile/${id}`)
    .then((res) => {
      if (res.status) {
        
        dispatch({ type: ActionType.UPDATE_PROFILE, payload: res.agency });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  
  axios
    .post("admin/agency/updateAgencyPassword", data)
    .then((res) => {
      
      if (res.data.status) {
        setToast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: ActionType.UNSET_AGENCY });
          window.location.href = "/";
        }, [3000]);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const profileUpdate = (agencyId ,formData) => (dispatch) => {
  
  axios
    .patch(`admin/agency/update/${agencyId}`, formData)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_PROFILE,
          payload: res.data.agency,
        });
        setToast("success", "Agency update Successfully");
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};
