import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Dashboard from "../Table/dashboard/Dashboard";
import { useEffect } from "react";
import "../../assets/js/custom";
import HostInfo from "../Table/Host/HostInfo";
import Host from "../Table/Host/Host";
import AgencyProfile from "./AgencyProfile";
import AllSettlement from "../Table/Settlement/AllSettlement"
import SettlementSolved from "../Table/Settlement/SettlementSolved"
import SettlementPending from "../Table/Settlement/SettlementPending"
import HostWeekSettlement from "../Table/Settlement/HostWeekSettlement";
import HostSettleMent from "../Table/Settlement/HostSettleMent";
import HostHistory from "../Table/Host/History/HostHistory";
import Payouts from "../Table/payouts/Payouts";
import Commission from "../Table/Commission/Commission";

const Agency = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/agency" ||
      location.pathname == ""
    ) {
      window.location.href = "/agency/dashboard";

      // window.history.pushState(null, null, "/agency/dashboar");

      navigate("/agency/dashboard");
    }
  }, [location.pathname]);

  return (
    <>
      <div className="mainContainer d-flex w-100">
        <div className="containerLeft">
          <Sidebar />
        </div>
        <div className="containerRight w-100 ">
          <Navbar />

          <div className="mainAdmin me-4">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              {/* <Route path="/user" element={<User />} /> */}
              {/* <Route path="/setting" element={<Setting />} /> */}
              {/* <Route path="/plan" element={<CoinPlan />} /> */}
              {/* <Route path="/planHistory" element={<PurchaseHistory />} /> */}
              {/* <Route path="/agency" element={<Agency />} /> */}
              <Route path="/host" element={<Host />} />
              <Route path="/Commission" element={<Commission />} />
              {/* <Route path="/hostCreate" element={<HostCreate />} /> */}
              <Route path="/agencyProfile" element={<AgencyProfile />} />
              {/* <Route path="/category" element={<Category />} /> */}
              {/* <Route path="/gift" element={<Gift />} /> */}
              {/* <Route path="/flashCoin" element={<FlashCoin />} /> */}
              <Route path="/hostInfo" element={<HostInfo />} />
              <Route path="/allSettlement" element={<AllSettlement />} />
              <Route path="/settlementSolved" element={<SettlementSolved />} />
              <Route
                path="/settlementPending"
                element={<SettlementPending />}
              />
              <Route
                path="/hostSettleMent"
                element={<HostSettleMent />}
              />
                <Route
                path="/hostWeekSettlement"
                element={<HostWeekSettlement />}
              />
               <Route path="/host/hostHistory" element={<HostHistory />} />
              <Route path="/payouts" element={<Payouts />} />
              {/* <Route path="/agencyWiseHost" element={<AgencyWiseHost/>}/> */}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Agency;
