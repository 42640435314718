import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Searching from "../../extra/Searching";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import { hostWiseAgencySettlement } from "../../store/settlement/settlement.action";
import { useLocation, useNavigate } from "react-router-dom";
import Analytics from "../../extra/Analytics";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";

const HostSettleMent = (props) => {
  const { agencyHostSettlement, historyCount, totalRevenue } = useSelector(
    (state) => state.settlement
  );

  const { state } = useLocation();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const stateObj = JSON.parse(localStorage.getItem("hostState"));
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    stateObj
      ? stateObj?.startDate
      : dialogueData?.startDate
      ? dialogueData?.startDate
      : "ALL"
  );
  const [endDate, setEndDate] = useState(
    stateObj
      ? stateObj?.endDate
      : dialogueData?.endDate
      ? dialogueData?.endDate
      : "ALL"
  );
  const [dayType, setDayType] = useState("WEEK");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    props.hostWiseAgencySettlement(
      stateObj?.agencyId ? stateObj?.agencyId : dialogueData?._id,
      dayType,
      startDate,
      endDate,
      currentPage,
      rowsPerPage,
      search == "" ? "ALL" : search
    );
  }, [
    dialogueData,
    state,
    startDate,
    endDate,
    currentPage,
    rowsPerPage,
    search,
  ]);

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", goPrevious);
    return () => {
      window.removeEventListener("popstate", goPrevious);
    };
  }, []);

  useEffect(() => {
    setData(agencyHostSettlement);
  }, [agencyHostSettlement]);

  const goPrevious = () => {
    if (dialogueData) {
      dispatch({ type: CLOSE_DIALOGUE });
      localStorage.removeItem("dialogueData");
    } else {
      navigate(-1);
    }
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const showHostSettlement = (data) => {
    const state = {
      id: data?.hostId,
      name: data?.host?.name,
      startDate: data?.startDate,
      endDate: data?.endDate,
    };
    navigate("/agency/host/hostHistory", {
      state: state,
    });
    localStorage.setItem("stateId", JSON.stringify(state));
  };

  const mapData = [
    {
      Header: "NO",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Host Id",
      // body: "host.uniqueId",
      Cell: ({ row }) => <span>{row.host?.uniqueId}</span>,
    },
    {
      Header: "Host Name",
      body: "host.name",
      Cell: ({ row }) => <span>{row?.host?.name}</span>,
    },
    {
      Header: "Host Coin",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <div className="bocBorder m-auto cursor" style={{ width: "6rem" }}>
          <span
            onClick={() => showHostSettlement(row)}
            className=" text-success"
          >
            {row?.coinEarned}
          </span>
        </div>
      ),
    },
    {
      Header: "Bonus/Penalty",
      body: "bonusOrPenaltyAmount",
      Cell: ({ row }) => (
        <span
          style={{
            color:
              row?.bonusOrPenaltyAmount > 0
                ? "green"
                : row?.bonusOrPenaltyAmount < 0
                ? "red"
                : "black",
          }}
        >
          {row?.bonusOrPenaltyAmount}
          &nbsp;
          <i className="fa-solid fa-award" />
        </span>
      ),
    },
    {
      Header: "Final",
      body: "amount",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span
          onClick={() => showHostSettlement(row)}
          className="cursor textPink"
        >
          {row?.amount}
        </span>
      ),
    },
    {
      Header: "Amount",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span
          onClick={() => showHostSettlement(row)}
          className="cursor text-primary"
        >
          {row.dollar + " $ "}
        </span>
      ),
    },
  ];

  const name = data?.[0]?.agencyId?.name;
  return (
    <div className="settlementTable  position-relative">
      <div className="settlementHeader primeHeader boxBetween">
        <Title name={`Agency Settlement`} />
      </div>
      <div className="d-flex justify-content-end mx-4 mt-4">
        {/* <h5>
          <span className="text-primary">
            {state ? state?.agencyName : dialogueData?.name}
          </span>
          ' Agency
        </h5> */}
        <button className="btn btn-primary btn-icon px-4" onClick={goPrevious}>
          <i class="fa-solid fa-angles-left text-white fs-6"></i>
        </button>
      </div>
      <div className="primeHeader mt-4 row">
        {/* <div className="col-md-6 p-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div> */}
        <div className="col-md-6 p-0 ">
          <span className="totalTex"> Total Revenue :</span>
          <span className="totalDollar fw-bold text-center text-success">
            {totalRevenue ? totalRevenue : "0"}
          </span>
        </div>
        <div className="d-flex justify-content-end col-md-6 m-0 p-0">
          <Searching
            type={"server"}
            data={agencyHostSettlement}
            setData={setData}
            setSearchData={setSearch}
            onFilterData={handleFilterData}
            serverSearching={handleFilterData}
            button={true}
            column={mapData}
            placeholder={"Searching Agency..."}
          />
        </div>
      </div>

      <div className="userMain">
        <div className="tableMain mt-3">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
          />
        </div>
      </div>
      <div className="paginationFooter">
        <Pagination
          component="div"
          count={agencyHostSettlement?.length}
          type={"server"}
          serverPerPage={rowsPerPage}
          totalData={historyCount}
          serverPage={currentPage}
          onPageChange={handleChangePage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <div className="userFooter primeFooter"></div>
    </div>
  );
};

export default connect(null, { hostWiseAgencySettlement })(HostSettleMent);
