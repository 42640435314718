import { combineReducers } from "redux";
import { dialogueReducer } from "./dialogue/dialogue.reducer";
import { agencyReducer } from "./agency/agency.reducer";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { hostReducer } from "./host/host.reducer";
import { settlementReducer } from "./settlement/settlement.reducer";
import { payoutsReducer } from "./payouts/Payouts.reducer";

export default combineReducers({
  dialogue: dialogueReducer,
  agency: agencyReducer,
  dashboard: dashboardReducer,
  host: hostReducer,
  settlement : settlementReducer,
  payouts:payoutsReducer
});
