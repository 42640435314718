import { useEffect, useState } from "react";
import DashboardBox from "./DashboardBox";
import {
  getDashboard,
  getHostChart,
  getSettlementChart,
} from "../../store/dashboard/dashboard.action";
import { connect, useDispatch, useSelector } from "react-redux";
import NewTitle from "../../extra/Title";
import Analytics from "../../extra/Analytics";
import Spinner from "../../util/Spinner";
import { BeatLoader } from "react-spinners";
import bgdark from "../../../assets/images/bgdark.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
// import faker from 'faker';

// Register the scales
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = (props) => {
  const { dashBoard, hostChart, revenueChart } = useSelector(
    (state) => state.dashboard
  );

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [revenueData, setRevenueData] = useState();
  const [hostChartData, setHostChartData] = useState();
  const { agency } = useSelector((state) => state?.agency);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (agency?._id) {
      dispatch(getDashboard(startDate, endDate, agency?._id));
      dispatch(getHostChart("host", startDate, endDate, agency?._id));
      dispatch(getSettlementChart("settlement", "ALL", "ALL", agency?._id));
    }
  }, [dispatch, agency, startDate, endDate]);

  useEffect(() => {
    // setRevenueData(revenueChart);
    setHostChartData(hostChart);
  }, [hostChart]);

  const labelsRevenue =
    revenueChart &&
    revenueChart.map((item) => {
      if (item && item?.startDate) {
        // const [datePart] = item._id.split("T");
        return item?.startDate;
      } else {
        return null; // Handle the case when item or item._id is undefined or null
      }
    });

  const countsRevenue =
    revenueChart && revenueChart?.map((item) => item?.amount);
  const chartDataRevenue = {
    labels: labelsRevenue,
    datasets: [
      {
        label: "Settlement",
        data: countsRevenue,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const optionsRevenue = {
    title: {
      display: true,
      text: "Count by Date",
    },
    maintainAspectRatio: false,
  };

  const labelsHost =
    hostChartData &&
    hostChartData.map((item) => {
      if (item && item._id) {
        const [datePart] = item._id.split("T");
        return datePart;
      } else {
        return null; // Handle the case when item or item._id is undefined or null
      }
    });
  const countsUser = hostChartData && hostChartData?.map((item) => item?.count);

  const chartDataUser = {
    labels: labelsHost,
    datasets: [
      {
        label: "Hosts",
        data: countsUser,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const optionsUser = {
    title: {
      display: true,
      text: "Count by Date",
    },
    maintainAspectRatio: false,
  };



  return (
    <>
      <div className="dashboard">
        <div className="dashboardHeader primeHeader ">
          <div className="d-flex flex-wrap">
            <div className="col-9 ">
              <NewTitle name={`Dashboard`} />
            </div>
            <div className="col-md-3 justify-content-end d-flex flex-wrap pt-3 com-sm-12">
              <div className="d-flex h3 ">
                Welcome<div className="text-nowrap h3">&nbsp; !</div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="mt-4 col-lg-8 col-md-6">
              <Analytics
                analyticsStartDate={startDate}
                analyticsStartEnd={endDate}
                analyticsStartDateSet={setStartDate}
                analyticsStartEndSet={setEndDate}
              />
            </div>
            <div className="col-lg-4 col-sm-12 p-0 d-flex my-auto justify-content-center">
              <span
                className="totalTex text-bg-dark p-3 text-center"
                style={{ width: "240px" }}
              >
                Agency Balance :
              </span>
              <span
                className="totalDollar text-center p-3 fw-bold text-dark"
                style={{ width: "272px" }}
              >
                <span>
                  {" "}
                  {dashBoard?.agencyBalInCoin}
                </span>

                <span className=" fw-bold text-primary">
                  &nbsp;(
                  {dashBoard?.agencyBalInDollar
                    ? dashBoard?.agencyBalInDollar + "$"
                    : "0$"}
                  )
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="dashBoardMain px-4">
          <div className="row">
        
            <div
              className="adminProfileBox px-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 cursor"
              onClick={() => navigate("/agency/host")}
            >
              <div className="dashBoxData p-0 dashboard-activity-one">
                <h4
                  className="py-2 text-center m-0"
                  style={{ color: "#242859" }}
                >
                  Host Details
                </h4>
                <div
                  className="bg-white p-3"
                  style={{
                    borderRadius: "0px 0px 15px 15px",
                    height: "170px",
                  }}
                >
                  <div className="p2 boxBetween">
                    <div>Today's New Host</div>
                    <div>
                      {dashBoard?.todayRealHost
                        ? dashBoard?.todayRealHost
                        : "0"}
                    </div>
                  </div>
                  <hr />
                  <div className="fw-bold h5 boxBetween p2">
                    Total Host:
                    <span>
                      &nbsp;&nbsp;
                      {dashBoard?.totalRealHost
                        ? dashBoard?.totalRealHost
                        : "0"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="adminProfileBox px-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 cursor"
            >
              
              <div className="dashBoxData p-0 dashboard-activity-one" onClick={() => navigate("/agency/host")}>
                <h4
                  className="py-2 text-center m-0"
                  style={{ color: "#242859" }}
                >
                  Host Activity
                </h4>
                <div
                  className="bg-white p-3"
                  style={{ borderRadius: "0 0 15px 15px", height: "170px" }}
                >
                  <div className="p2 boxBetween m">
                    <div>Online Host</div>
                    <div> {dashBoard?.hostData ? dashBoard?.hostData.onlineCount : "0"}</div>
                  </div>
                  <hr className="lineCss" />
                  <div className="p2 boxBetween">
                    <div>Live Host</div>
                    <div> {dashBoard?.hostData ? dashBoard?.hostData.liveCount : "0"}</div>
                  </div>
                  <hr className="lineCss" />
                  <div
                    className="p2 boxBetween"
                    style={{ borderRadius: "0 0 15px 15px" }}
                  >
                    <div>Busy Host</div>
                    <div> {dashBoard?.hostData ? dashBoard?.hostData.busyCount : "0"}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="adminProfileBox px-2 col-xl-4 col-lg-6 col-md-6 col-sm-12 cursor"
            >
              <div className="dashBoxData p-0 dashboard-activity-two" onClick={() => navigate("/agency/allSettlement")} >
                <h4
                  className="py-2 text-center m-0"
                  style={{ color: "#242859" }}
                >
                  Agency Settlement
                  
                </h4>
                <div
                  className="bg-white p-3"
                  style={{ borderRadius: "0 0 15px 15px", height: "170px" }}
                >
                 
                  <div className="p2 boxBetween">
                    <div>Current revenue (coin)</div>
                    <div className="d-flex">
                      <div></div>
                      <div>
                        {dashBoard?.currentRevenue
                          ? dashBoard?.currentRevenue
                          : "0"}&nbsp; 
                      </div>
                    </div>
                  </div>
                  <hr className="lineCss" />
                  <div className="p2 boxBetween">
                    <div>Last Settlement</div>
                    <div className="d-flex">
                      <div></div>
                      <div>
                        {dashBoard?.lastRevenueCoin?.amount
                          ? dashBoard?.lastRevenueCoin?.amount
                          : "0"}

                        <span className="cursor text-primary">
                          &nbsp; (
                          {"$" +
                            (dashBoard?.lastRevenueCoin?.dollar
                              ? dashBoard?.lastRevenueCoin?.dollar
                              : " 0")}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr className="lineCss" />
                  <div className="fw-bold h5 boxBetween p2">
                    Total :
                    <span>
                      &nbsp;
                      {"$" +
                        (dashBoard?.lastRevenueCoin?.dollar
                          ? dashBoard?.lastRevenueCoin?.dollar
                          : " 0")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="dashChart col-lg-12 col-md-12">
              <div className="charts row gx-3">
                <div className="col-md-12 col-sm-12 col-lg-6 mt-2">
                  <div className="chart1">
                    <div
                      className="chartHeader h4 text-center py-2"
                      style={{ color: "#242859" }}
                    >
                      Host Chart
                    </div>
                    <div
                      className="bg-white w-100 p-2"
                      style={{
                        borderRadius: "0 0 15px 15px",
                        height: "390px",
                      }}
                    >
                      {hostChart || hostChart?.length > 1 ? (
                        <Line data={chartDataUser} options={optionsUser} />
                      ) : (
                        <div
                          style={{
                            marginTop: "-13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          No data available for the User chart.
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-lg-6 mt-2">
                  <div className="chart1 ">
                    <div
                      className="chartHeader h4 text-center py-2"
                      style={{ color: "#242859" }}
                    >
                      Settlement Chart
                    </div>
                    <div
                      className="bg-white w-100 p-2 m-auto"
                      style={{
                        borderRadius: "0 0 15px 15px",
                        height: "390px",
                      }}
                    >
                      {revenueChart || revenueChart?.length > 1 ? (
                        <Line
                          data={chartDataRevenue}
                          options={optionsRevenue}
                        />
                      ) : (
                        <div
                          style={{
                            marginTop: "-13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          No data available for the Settlement chart.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getDashboard,
  getHostChart,
  getSettlementChart,
})(Dashboard);
