import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useLocation, useNavigate } from "react-router-dom";
import IOSSwitch from "@material-ui/core/Switch";
// import { hostAccept } from "../store/hostRequest/hostRequest.action";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getHostProfile,
  blockUnblockSwitch,
  updateHostCoin,
} from "../../store/host/host.action";
import EdiText from "react-editext";
import { setToast } from "../../util/toast";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../store/dialogue/dialogue.type";

import Title from "../../extra/Title";
import Host1 from "../../../../src/assets/images/host1.jpeg";
import host2 from "../../../../src/assets/images/1.jpeg";
import host1 from "../../../../src/assets/images/host8.jpeg";
import host3 from "../../../../src/assets/images/host3.jpeg";
import host4 from "../../../../src/assets/images/host4.jpeg";
import host5 from "../../../../src/assets/images/host5.jpeg";
import host6 from "../../../../src/assets/images/host6.jpeg";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import ToggleSwitch from "../../extra/ToggleSwitch";

const hostAlbum = [Host1, host2, host3, host4, host5, host6];
const HostInfo = (props) => {
  const { hostProfile } = useSelector((state) => state.host);
  const { dialogueData, dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState("");
  const location = useLocation();
  const [showBigImage, setShowBigImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  // const [numImagesToShow, setNumImagesToShow] = useState(6);

  useEffect(() => {
    dispatch(getHostProfile(dialogueData ? dialogueData?._id : state));
  }, [dispatch, state]);

  useEffect(() => {
    setData(hostProfile);
  }, [hostProfile]);

  const handleOpenUrl = (url) => {
    window.open(url, "https://video.vidyard.com/watch/aBSbzZ5L2JPBCaV3EnK2qg?");
  };

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", goPrevious);
    return () => {
      window.removeEventListener("popstate", goPrevious);
    };
  }, []);

  const goPrevious = () => {
    localStorage.removeItem("dialogueData");
    localStorage.removeItem("hostInfo");
    localStorage.removeItem("agencyWiseHost");
    dispatch({ type: CLOSE_DIALOGUE });
  };
  const handleClick = (hostDetail) => {
    props.blockUnblockSwitch(
      hostDetail,
      hostDetail.isBlock === true ? false : true
    );
  };
  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };
  const handleSave = (val) => {
    if (val < 0) {
      setToast("error", "Invalid Coin");
    } else {
      const coinValid = isNumeric(val);
      if (!coinValid) {
        setToast("error", "Invalid Coin");
      } else {
        props.updateHostCoin(val, data._id);
      }
    }
  };
  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const handleHistory = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const showImage = (imageURL) => {
    window.open(imageURL, "_blank");
  };
  return (
    <>
      <div className="mainHostProfile">
        <div className="hostProfile">
          <div className="hostProfileHeader primeHeader d-flex align-items-center justify-content-between">
            <div className="col-12">
              <div className="">
                <div className="col-12">
                  <Title name={`Host Profile`} />
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-between">
                <h4 className="page-title mb-0  text-capitalize col-6 ">
                  <span className="text-primary">{data?.name + "'s"}</span>{" "}
                  Details
                </h4>
                <div className="ProfileBtn d-flex col-6 flex-wrap  justify-content-end">
                  {dialogueData?.type === 1 && (
                    <button
                      className="btn btn-success btn-icon px-4 mx-2"
                      onClick={() =>
                        state
                          ? handleHistory(data, "hostHistory")
                          : handleProfile(data, "hostHistory")
                      }
                    >
                      <i class="fa-solid fa-history text-white fs-6"></i>
                    </button>
                  )}

                  <button
                    className="btn btn-primary btn-icon px-4"
                    onClick={goPrevious}
                  >
                    <i class="fa-solid fa-angles-left text-white fs-6"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hostProfileMain  rounded row ">
            <div>
              <div className="align-items-center bg-white mainHostImage row g-0 p-3 allDetails m-0">
                <div className="hostImageBox d-flex col-lg-12 col-xl-6 col-md-12 col-sm-12 justify-content-center align-items-center ">
                  <img
                    className=" rounded profile-image"
                    style={{ cursor: "pointer" }}
                    src={data?.profilePic ? data?.profilePic : Host1}
                    width={350}
                    height={280}
                    onClick={() => showImage(data?.profilePic)}
                  />
                </div>
                <div className="col-xl-6 col-sm-12 col-md-12 col-lg-12  d-grid align-items-center">
                  <div className="allDetails bg-white p-2">
                    <table className="uTable w-100">
                      <tr>
                        <td> Name</td>
                        <td>:</td>
                        <td>{data?.name}</td>
                      </tr>
                      <tr>
                        <td>Unique Id</td>
                        <td>:</td>
                        <td>{data?.uniqueId}</td>
                      </tr>
                      <tr>
                        <td>Password</td>
                        <td>:</td>
                        <td>{data?.password}</td>
                      </tr>
                      <tr>
                        <td>Bio</td>
                        <td>:</td>
                        <td>{data.bio ? data.bio : "Hi Dear"}</td>
                      </tr>
                      <tr>
                        <td>Age</td>
                        <td>:</td>
                        <td>{data.age ? data.age : "-"}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="mainHostData">
                <div className="col-12">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-12 col-sm-12">
                      <div className="allDetails bg-white px-4 py-3 shadow-sm mt-4 ">
                        <table className="uTable w-100">
                          <tr>
                            <td>Status</td>
                            <td>:</td>
                            <td>
                              {data?.isOnline === true ? (
                                <div
                                  className="bg-success py-1 px-2 d-inline-block text-white"
                                  style={{
                                    fontSize: "11px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  Online
                                </div>
                              ) : (
                                <div
                                  className="bg-danger py-1 px-2 d-inline-block text-white"
                                  style={{
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Offline
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="py-0">Block</td>
                            <td className="py-0">:</td>
                            <td className="py-0">
                              {/* <IOSSwitch
                          checked={data?.isBlock == true ? true : false}
                          color="primary"
                          onClick={() => handleClick(data)}
                        /> */}
                              <ToggleSwitch
                                value={data?.isBlock}
                                onClick={() => handleClick(data)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width="110px">Coin</td>
                            <td width="50px">:</td>
                            <td>
                              <EdiText
                                type="number"
                                value={data?.coin}
                                onSave={handleSave}
                              />
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Call Charge</td>
                            <td>:</td>
                            <td>{data?.callCharge}</td>
                          </tr> */}
                          <tr>
                            <td>Gender</td>
                            <td>:</td>
                            <td>{data?.gender}</td>
                          </tr>

                          <tr>
                            <td>Country</td>
                            <td>:</td>
                            <td>{data?.countryId?.name}</td>
                          </tr>
                          <tr>
                            <td>Last Login</td>
                            <td>:</td>
                            <td>{data?.lastLogin}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 col-sm-12">
                      <div className="allDetails bg-white px-4 shadow-sm mt-4 ">
                        <table className="uTable w-100 ">
                          <tr className="row">
                            <div className="" style={{ overflowY: "auto" }}>
                              {/* <div className="row m-4 g-0 justify-content-between justify-content-sm-center shadow-sm allDetails bg-white">
                          {hostProfile?.image?.length > 0
                            ? hostProfile?.image?.map((image) => {
                                return (
                                  <>
                                    <div className="col-lg-4 col-md-4 col-sm-6  p-4 hostImages">
                                      <img
                                        src={image}
                                        alt=""
                                        draggable="false"  
                                        style={{cursor :"pointer"}}                                   
                                        className="mx-auto myImage"
                                        onClick={() => showImage(image)}
                                      />
                                    </div>
                                  </>
                                );
                              })
                            : hostAlbum.map((image) => {
                                return (
                                  <>
                                    <div className="col-lg-4 col-md-4 col-sm-6  p-4 hostImages">
                                      <img
                                        src={image}
                                        alt=""
                                        draggable="false"
                                        style={{
                                          // width: "130px",
                                          // height: "130px ",
                                          objectFit: "cover",
                                       cursor:"pointer",
                                          display: "block",
                                          // borderRadius: "10px",
                                        }}
                                        className="mx-auto myImage"
                                        onClick={() => showImage(image)}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                        </div> */}
                              <div
                                className="row allDetails shadow-sm bg-white mt-4"
                                style={{
                                  minHeight: "350px",
                                  height: "350px",
                                  overflowY: "auto",
                                }}
                              >
                                {hostProfile?.image
                                  // ?.slice(0, numImagesToShow)
                                  ?.map((image, index) => (
                                    <div
                                      key={index}
                                      className="col-lg-4 col-md-4 col-sm-6 py-3 hostImages boxCenter"
                                    >
                                      {/* Your image rendering code */}
                                      <img
                                        src={image}
                                        alt=""
                                        draggable="false"
                                        className="mx-auto myImage"
                                        onClick={() => showImage(image)}
                                      />
                                    </div>
                                  ))}
                              </div>
                              {/* {hostProfile?.image?.length > numImagesToShow && (
                                <div
                                  className="showMoreButton text-primary cursor"
                                  onClick={() =>
                                    setNumImagesToShow(numImagesToShow + 6)
                                  }
                                >
                                  Show More
                                </div>
                              )} */}
                            </div>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getHostProfile,
  blockUnblockSwitch,
  updateHostCoin,
})(HostInfo);
