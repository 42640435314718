import { connect, useDispatch, useSelector } from "react-redux";
import { allHostSettlement } from "../../store/settlement/settlement.action";
import { useEffect, useState } from "react";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import AgencyWeekSettlement from "./HostWeekSettlement";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import SettlementActionDialogue from "./SettlementActionDialogue";
import { useLocation, useNavigate } from "react-router-dom";
import BonusInfo from "./BonusInfo";

const AllSettlement = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const { settlement, total } = useSelector((state) => state.settlement);
  const { agency } = useSelector((state) => state?.agency);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    props.allHostSettlement(agency?._id, currentPage, rowsPerPage);
  }, [agency?._id, currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setData(settlement);
  }, [settlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const handleProfile = (row, type) => {
    navigate("/agency/hostSettleMent", {
      state: { id: row?.agencyId, data: row, type: "custom" },
    });
    localStorage.setItem("hostState", JSON.stringify(row));
  };
  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Start Date", body: "startDate" },
    { Header: "End Date", body: "endDate", style: { width: "200px" } },
    { Header: "Total Host", body: "totalHost" },
    {
      Header: "Revenue",
      body: "coinEarned",
      sorting: { type: "client" },
    },
    {
      Header: "Commission",
      body: "commissionCoinEarned",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span>
          {row?.commissionCoinEarned} &nbsp;
          <span className="text-success">
            ({row?.agencyCommisionPercentage + "%"})
          </span>
        </span>
      ),
    },
    {
      Header: "Total Revenue",
      body: "totalCoinEarned",
      sorting: { type: "client" },

    },
    {
      Header: "Penalty/Bonus",
      body: "bonusOrPenltyAmount",
      sorting: { type: "client" },
      Cell: ({ row }) => <span onClick={() => {
        dispatch({
          type: OPEN_DIALOGUE,
          payload: { type: "bonusInfo", data: row },
        });
      }} className={`cursor ${row?.bonusOrPenltyAmount > 0 ? "text-success" : row?.bonusOrPenltyAmount === 0 ? "text-dark" : "text-danger"}`}>{row?.bonusOrPenltyAmount}</span>

    },
    { Header: "Final", body: "finalAmountTotal", sorting: { type: "client" } },
    {
      Header: "Amount",
      Cell: ({ row }) => <span>{row?.dollar.toFixed(2) + " $ "}</span>,
    },

    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row?.statusOfTransaction == 2 ? (
          <div
            className="bg-success py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Paid
          </div>
        ) : (
          <div
            className="bg-danger py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Pending
          </div>
        ),
    },
    {
      Header: "Payout Date",
      Cell: ({ row }) => <span>{row?.payoutDate ? row?.payoutDate : "-"}</span>,
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-info`}
          onClick={() => handleProfile(row, "agencyWeekSettlement")}
        />
      ),
    },
  ];

  const openInfo = () => {

  }

  return (
    <>
      <div className="mainSettlementTable">
        {dialogue && dialogueType === "bonusInfo" && <BonusInfo />}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`All Settlement`} />
            </div>
            <div className="d-flex justify-content-end col-md-12 ">
              <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                <Searching
                  type={"client"}
                  data={settlement}
                  setSearchData={setSearch}
                  setData={setData}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  searchValue={search}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
              />
            </div>
            <Pagination
              component="div"
              count={settlement?.length}
              serverPage={currentPage}
              type={"server"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={total}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { allHostSettlement })(AllSettlement);
