import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./agency.type";
import jwt_decode from "jwt-decode";

const initialState = {
  agency: {},
  isAuth: false,
};

export const agencyReducer = (state = initialState, action) => {
  let decode;
  switch (action.type) {
    case ActionType.SET_AGENCY:
      if (action.payload) {
        decode = jwt_decode(action.payload);
      }
      
      setToken(action.payload);
      SetDevKey(key);
      sessionStorage.setItem("TOKEN", action.payload);
      sessionStorage.setItem("Key", key);
      sessionStorage.setItem("agencyId", decode)
      return {
        ...state,
        isAuth: true,
        agency: decode,
      };

    case ActionType.UNSET_AGENCY:
    
    window.localStorage.clear();
    window.sessionStorage.clear();
      SetDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth: false,
        agency: {},
      };
    // case SEND_EMAIL:
    //   return {
    //     ...state,
    //     agency: action.payload,
    //   };

    case ActionType.UPDATE_PROFILE:
      return {
        ...state,
        agency: {
          ...state.agency,
          _id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.image,
           flag: action.payload.flag,
        },
      };
    case ActionType.LOGIN_AGENCY:
      if (action.payload) {
        decode = jwt_decode(action.payload);
      }
      // Set Token And Key In Axios
      setToken(action.payload);
      SetDevKey(key);
      // Set Token And Key In Session
      sessionStorage.setItem("token", action.payload);
      sessionStorage.setItem("key", key);
      sessionStorage.setItem("isAuth", true);
      return {
        ...state,
        agency: decode,
        isAuth: true,
      };
    case ActionType.LOGOUT_AGENCY:
      sessionStorage.removeItem("key", key);
      sessionStorage.removeItem("token", setToken);
      sessionStorage.removeItem("isAuth", false);
      setToken(null);
      SetDevKey(null);
      return {
        ...state,
        agency: {},
        isAuth: false,
      };
    default:
      return state;
  }
};
