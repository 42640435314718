import { connect, useDispatch, useSelector } from "react-redux";
import Title from "../extra/Title";
import Admin from "../../assets/images/admin.png";
import Addimg from "../../assets/images/addImg.jpg";
import Button from "../extra/Button";
import Input from "../extra/Input";
import { useEffect, useState } from "react";
import { setToast } from "../util/toast";
import {
  getProfile,
  changePassword,
  profileUpdate,
} from "../store/agency/agency.action";
import { permissionError } from "../util/Alert";
import { baseURL } from "../util/config";
const AgencyProfile = (props) => {
  const agency = useSelector((state) => state?.agency?.agency);
  const hasPermission = useSelector((state) => state.admin?.flag);

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [error, setError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [boxType, setBoxType] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    if (agency?._id) {
      dispatch(getProfile(agency?._id));
    }
  }, [dispatch, agency?._id]);

  useEffect(() => {
    setName(agency.name);
    setCode(agency.code);
    setImagePath(agency.image);
    setError({ name: "", code: "" });
  }, [agency]);

  const handleEditName = (agencyId) => {
    // if (!hasPermission) return permissionError();

    if (!name || !code) {
      let error = {};
      if (!name) error.name = "name is required !";
      if (!code) error.code = "code is required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("code", code);

      props.profileUpdate(agencyId, formData);
    }
  };
  // const handleUploadImage = (e) => {
  //   setImage(e.target.files[0]);
  //   setImagePath(URL.createObjectURL(e.target.files[0]));
  // };
  const handleEditImage = () => {
    document.getElementById("profileImage").click();
  };

  const handleUploadImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUpdateImage = (agencyId) => {
    // if (!hasPermission) return permissionError();
    if (!image || image.length === 0) {
      setToast("info", "Please select image");
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("code", code);
      props.profileUpdate(agencyId, formData);
      setImage(null);
    }
  };

  const handleChangePassword = () => {
    // if (!hasPermission) return permissionError();
    if (
      !newPassword ||
      !confirmPassword ||
      newPassword !== confirmPassword ||
      !oldPassword
    ) {
      let error = {};

      if (!newPassword) error.newPassword = "New password is required !";
      if (!confirmPassword)
        error.confirmPassword = "Confirm password Is required !";
      if (newPassword !== confirmPassword)
        error.confirmPassword =
          "New password and confirm password doesn't match";
      if (!oldPassword) error.oldPassword = "Old password is required !";
      return setError({ ...error });
    } else {
      let data = {
        oldPass: oldPassword,
        newPass: newPassword,
        confirmPass: confirmPassword,
      };
      props.changePassword(data);
    }
  };

  // const handlePrevious = (url) => {
  //   window.open(url, "_blank");
  //   onClick={() => handlePrevious(imagePath)}
  // };

  return (
    <>
      <div className="mainProfile">
        <div className="adminProfile">
          <div className="profileHeader primeHeader">
            <div className="row">
              <div className="col-6">
                <Title name={`Profile`} />
              </div>
              <div className="col-6"></div>
            </div>
          </div>
          <div className="profileMain p-4">
            <div className="row">
              <div
                className="adminProfileBox col-md-6 col-lg-4 col-sm-12 position-relative"
                style={{ borderRadius: "15px" }}
              >
                <div className="settingBox">
                  <div className="avatar avatar-xl" onClick={handleEditImage}>
                    <input
                      name="image"
                      id="profileImage"
                      type="file"
                      hidden="hidden"
                      accept="image/*"
                      onChange={handleUploadImage}
                    ></input>
                    <div className="adminProfileImg d-flex justify-content-center container mt-2">
                      <img
                        src={imagePath ? imagePath : Admin}
                        alt=""
                        className="adminImg"
                      />
                      <div className="middle">
                        <i className="fas fa-edit fa-lg text-primary"></i>
                      </div>
                    </div>
                  </div>

                  <div className="fs-4 text-center fw-bold ">
                    {agency?.name}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      btnName={`Upload Image`}
                      onClick={() => handleUpdateImage(agency?._id)}
                      btnColor={`bg-success text-white`}
                      newClass={`mt-2 my-auto`}
                      style={{
                        width: "140px",
                        borderRadius: "5px",
                      }}
                      // style={{
                      //   width: "200px",
                      //   borderRadius: "5px",
                      //   pointerEvents:
                      //     !image || image.length === 0 ? "none" : "auto",
                      //   opacity: !image || image.length === 0 ? 0.5 : 1,
                      // }}
                      // disabled={!image || image.length === 0}
                    />
                  </div>
                  {/* {!image || image.length === 0 ? "* Please Select image " : ""} */}

                  <hr className="textPrime w-100 mt-3" />
                  <div className="mt-3">
                    <div className="adminField">
                      <Button
                        btnName={`Edit Profile`}
                        onClick={() => setBoxType(false)}
                        btnColor={` text-white ${
                          boxType === false ? "bg-dark" : "bg-secondary"
                        }`}
                        newClass={`py-3`}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </div>
                    <div className="adminField">
                      <Button
                        btnName={`Change Password`}
                        onClick={() => setBoxType(true)}
                        btnColor={``}
                        newClass={` text-white mt-3 py-3 ${
                          boxType === true ? "bg-dark" : "bg-secondary"
                        }`}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {boxType == false && (
                <div className="adminProfileBox col-md-6 col-lg-8 col-sm-12 ">
                  <div className="settingBox">
                    <div className="editProfile">
                      <div className="text-center fw-bold fs-3 py-2">
                        Edit Profile
                      </div>
                      <hr className="textPrime w-75 m-auto pb-5" />

                      <div className="d-grid gap-md-5 gap-sm-3">
                        <Input
                          type={`text`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          label={`Agency Name`}
                          placeholder={`Agency Name`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.name}
                        />
                        <Input
                          type={`password`}
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          label={`Agency Code`}
                          placeholder={`Agency code....`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.code}
                          readOnly={true}
                          style={{ opacity: "0.5" }}
                        />
                        <Button
                          btnName={`Submit`}
                          onClick={() => handleEditName(agency?._id)}
                          btnColor={`bg-success text-white`}
                          newClass={`mt-2 mx-auto`}
                          style={{
                            width: "100px",
                            borderRadius: "5px",
                            marginBottom: "70px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {boxType == true && (
                <div className="adminProfileBox col-md-6 col-lg-8 col-sm-12 ">
                  <div className="settingBox">
                    <div className="editPassword">
                      <div className="text-center fw-bold fs-3 py-2">
                        Change Password
                      </div>
                      <hr className="textPrime w-75 m-auto pb-5" />

                      <div className="d-grid gap-3">
                        <Input
                          type={`password`}
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          label={`Old Password`}
                          placeholder={`Old Password`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.oldPassword}
                        />
                        <Input
                          type={`password`}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          label={`New Password`}
                          placeholder={`New Password`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.newPassword}
                        />
                        <Input
                          type={`password`}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          label={`Confirm New Password`}
                          placeholder={`Confirm New Password`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.confirmPassword}
                        />
                        <Button
                          btnName={`Submit`}
                          onClick={handleChangePassword}
                          btnColor={`bg-success text-white`}
                          newClass={`mt-2 mx-auto`}
                          style={{
                            width: "100px",
                            borderRadius: "5px",
                            marginBottom: "20px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(null, { getProfile, changePassword, profileUpdate })(
  AgencyProfile
);
