import axios from "axios";
import * as ActionType from "./dashboard.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { baseURL, key } from "../../util/config";
import { apiInstanceFetch } from "../../util/api";

export const getDashboard = (startDate, endDate, agencyId) => (dispatch) => {
  
  apiInstanceFetch
    .get(
      `admin/dashboard/getRevenueAgencyPenal?startDate=${startDate}&endDate=${endDate}&agencyId=${agencyId}`
    )
    .then((res) => {
      ;
      dispatch({
        type: ActionType.GET_DASHBOARD,
        payload: { data: res.response, id: agencyId },
      });
    })
    .catch((error) => console.error(error));
};

export const getHostChart =
  (type, startDate, endDate, agencyId) => (dispatch) => {
    
    apiInstanceFetch
      .get(
        `admin/dashboard/chartForAgencyPenal?type=${type}&startDate=${startDate}&endDate=${endDate}&agencyId=${agencyId}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_HOST_CHART,
          payload: { data: res.host, id: agencyId },
        });
      })
      .catch((error) => console.error(error));
  };

export const getSettlementChart =
  (type, startDate, endDate, agencyId) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/dashboard/chartForAgencyPenal?type=${type}&startDate=${startDate}&endDate=${endDate}&agencyId=${agencyId}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_SETTLEMENT_CHART,
          payload: { data: res.settlement, id: agencyId },
        });
      })
      .catch((error) => console.error(error));
  };

// export const getDashboardHostFetch = (startDate, endDate) => {
//   return async (dispatch) => {
//     const url = `${baseURL}admin/dashboard/totalHostForAdminPenal?startDate=${startDate}&endDate=${endDate}`;
//     const requestOptions = {
//       method: "GET",
//       headers: { key: key },
//     };

//     try {
//       const response = await fetch(url, requestOptions);
//       const res = await response.json();
//       dispatch({
//         type: ActionType.GET_DASHBOARD_HOST_FETCH,
//         payload: res.host,
//       });
//     } catch (error) {
//       return console.log(error);
//     }
//   };
// };
