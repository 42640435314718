import axios, { Axios } from "axios";
import { data } from "jquery";
import { setToast } from "../../util/toast";

import * as ActionType from "./host.type";
import { apiInstanceFetch } from "../../util/api";

export const getHost = (search, start, limit , agencyId , sort , type) => (dispatch) => {
  
  apiInstanceFetch
    .get(`admin/host/getHostAgencyWise?search=${search}&type=1&start=${start}&limit=${limit}&agencyId=${agencyId}&sort=${sort}&sortType=${type}`)
    .then((res) => {
      
      if (res.status) {
        dispatch({
          type: ActionType.GET_HOST,
          payload: { data: res.host, total: res.total , id:agencyId },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const approvedDisableSwitch = (host, approved) => (dispatch) => {
  axios
    .put(`admin/host/${host._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.APPROVED_HOST,
          payload: { data: res.data.host, id: host._id },
        });

        setToast(
          "success",
          `${host.name} Is ${
            approved === true ? "Reject" : "Approved"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
export const blockUnblockSwitch = (host, block) => (dispatch) => {
  axios
    .put(`admin/host/blockUnblock?hostId=${host._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.BLOCKED_HOST,

          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

export const createHost = (formData) => (dispatch) => {
  axios
    .post(`admin/host/signup`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.CREATE_HOST, payload: res.data.host });
        setToast("success", "Host  created Successfully !");
      }
    })
    .catch((error) => console.error(error));
};

// UPDATE SELLER

export const updateHost = (formData, id) => (dispatch) => {
  axios
    .patch(`admin/host/updateProfileByAdmin?hostId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_HOST,
          payload: { data: res.data.host, id },
        });

        setToast("success", "Host Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const hostCountry = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/country`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.COUNTRY_HOST, payload: res.country });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

// host Accept

export const hostAccept = (id) => (dispatch) => {
  axios
    .put(`request/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.ACCEPT_HOST_REQUEST, payload: id });
        setToast("success", "Host Request Accept!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const getHostProfile = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`client/host/profile?hostId=${id}`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.GET_HOST_PROFILE, payload: res.host });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const updateHostCoin = (val, id) => (dispatch) => {
  axios
    .post(`admin/host/addLessCoinHost?`, { hostId: id, coin: val })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_HOST_COIN,
          payload: { editHostCoin: res.data.user },
        });
        setToast("success", "HostCoin Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

//  admin/host/addLessCoinHost

export const hostApproved = (host, type) => (dispatch) => {
  axios
    .put(`admin/host/approve?hostId=${host._id}&type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.APPROVED_HOST,
          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            type === "Approved" ? "Approved" : "Rejected"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

// REAL HOST AGENCY DROP DOWN
export const getAgencyDropDown = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/agency/all`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_AGENCY_DROPdOWN,
          payload: res.agency,
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

// AgenyWise Host

export const hostAgency = (search, start, limit, agencyId) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/host/getHostAgencyWise?&search=${search}&start=${start}&limit=${limit}&agencyId=${agencyId}`
    )
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_HOST_AGENCY_WISE,
          payload: { data: res.host, id: agencyId },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const getHostHistory =
  (hostId, type, start, limit, startDate, endDate) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/history/hisotryForHost?hostId=${hostId}&type=${type}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_HOST_HISTORY,
            payload: {
              history: res.history,
              totalCoin: res.totalCoin,
              total: res.total,
            },
          });
        }
      })
      .catch((error) => setToast(error.message));
  };

// host history

export const getHostLiveHistory =
  (id, start, limit, startDate, endDate) => (dispatch) => {
    
    apiInstanceFetch
      .get(
        `admin/liveStreamingHistory?hostId=${id}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_HOST_LIVE_HISTORY,
            payload: {
              history: res.history,
              totalCoin: res.totalCoin,
              total: res.total,
            },
          });
        }
      })
      .catch((error) => setToast("error", error));
  };
