import React, { useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getHostHistory } from "../../../store/host/host.action";

import moment from "moment";
import { useLocation } from "react-router-dom";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";

function HostAdminHistory(props) {
  const { history, total, totalCoin } = useSelector((state) => state.host);
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const { state } = useLocation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(state ? state?.startDate : "ALL");
  const [endDate, setEndDate] = useState(state ? state?.endDate : "ALL");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("0");
  const [filed, setField] = useState("");
  const hostId = JSON.parse(localStorage.getItem("stateId"));

  let propValue;
  if (state) {
    if (state.type === "agency") {
      propValue = dialogueData?._id;
    } else {
      propValue = state?.id;
    }
  } else {
    propValue = dialogueData?._id;
  }

  useEffect(() => {
    dispatch(
      getHostHistory(
        propValue ? propValue : hostId?.id,
        "admin",
        currentPage,
        rowsPerPage,
        startDate,
        endDate
      )
    );
  }, [dispatch, propValue, currentPage, startDate, endDate, rowsPerPage]);

  useEffect(() => {
    setData(history);
  }, [history]);

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event), 20);
    setCurrentPage(1);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // const handleChildValue = (value) => {
  //   setType(type === 0 ? 1 : 0);
  //   setField(value);
  // };

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Coin",
      body: "hCoin",
      sorting: { type: "client" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row?.hCoin}</span>
        ) : (
          <span className="text-danger fs-6 fw-bold">{row?.hCoin}</span>
        ),
    },

    {
      Header: "Date",
      body: "date",
      sorting: { type: "client" },
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="fw-semibold fs-6">
            {date && date.length > 0 ? date[0] : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="fw-semibold fs-6">
            {date && date.length > 1 ? date[1] : "N/A"}
          </span>
        );
      },
    },

    // add more columns as needed
  ];

  return (
    <>
      <div className="row d-flex align-items-center  py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMain tableMain border-top">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
            // onChildValue={handleChildValue}
          />
        </div>
        <Pagination
          component="div"
          count={data?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={total}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}

export default connect(null, { getHostHistory })(HostAdminHistory);
