// get host
export const GET_HOST = "GET_HOST";

// CREATE HOST
export const CREATE_HOST = "CREATE_HOST";

// UPDATE HOST
export const UPDATE_HOST = "UPDATE_HOST"

//DISABLE SWITCH
export const DISABLE_HOST = "DISABLE_HOST"

// COUNTRY DROPDOWN
export const COUNTRY_HOST = "COUNTRY_HOST";


//ACCEPT HOST REQUEST
export const ACCEPT_HOST_REQUEST = "ACCEPT_HOST_REQUEST";
 

// BLOCKED HOST

export const BLOCKED_HOST = "BLOCKED_HOST";

// Approved Host
export const APPROVED_HOST = "APPROVED_HOST";

//  Get Host Info
export const GET_HOST_PROFILE = "GET_HOST_PROFILE";

//  Update Host Coin
export const UPDATE_HOST_COIN = "UPDATE_HOST_COIN";

//  GET AGENCY DROPdOWN
export const GET_AGENCY_DROPdOWN = "GET_AGENCY_DROPdOWN";

// GET HOST AGENCYWISE
export const GET_HOST_AGENCY_WISE = "GET_HOST_AGENCY_WISE";

export const GET_HOST_HISTORY = "GET_HOST_HISTORY";

export const GET_HOST_LIVE_HISTORY = "GET_HOST_LIVE_HISTORY";