import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "../../assets/js/custom";
import Navigator from "../extra/Navigator";
import { warning } from "../util/Alert";
import { useDispatch } from "react-redux";
import { LOGOUT_AGENCY } from "../store/agency/agency.type";
import { CLOSE_DIALOGUE } from "../store/dialogue/dialogue.type";
import $ from "jquery";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as SettingIcon } from "../../assets/icons/Setting.svg";
import { ReactComponent as AgencyIcon } from "../../assets/icons/AgencyIcon.svg";
import { ReactComponent as HostIcon } from "../../assets/icons/HostIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/User.svg";
import { ReactComponent as Plan } from "../../assets/icons/Plan.svg";
import { ReactComponent as FlashCoin } from "../../assets/icons/FlashCoin.svg";
import { ReactComponent as Gift } from "../../assets/icons/Gift.svg";
import { ReactComponent as Commission } from "../../assets/icons/commission.svg";
import { ReactComponent as Profile } from "../../assets/icons/Plan.svg";
import { ReactComponent as LogOut } from "../../assets/icons/Logout.svg";
import { ReactComponent as Feedback } from "../../assets/icons/Feedback.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/DownArrow.svg";
import { ReactComponent as Settlement } from "../../assets/icons/settlement.svg";
import { useEffect, useState } from "react";
import CustomScript from "../../assets/js/CustomScript";

const Sidebar = () => {
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    const data = warning();
    data
      .then((logout) => {
        if (logout) {
          dispatch({ type: LOGOUT_AGENCY });
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleGift = () => {
    localStorage.setItem("GiftClick", true);
  };

  // const handelRemoveItem = () => {
  //   // localStorage.removeItem(JSON.parse("dialogueData"))

  //   localStorage.removeItem(JSON.parse("dialogueData"));
  // };

  const handelRemoveItem = () => {
    localStorage.removeItem("dialogueData");
    dispatch({ type: CLOSE_DIALOGUE });
  };

  // eslint-disable-next-line no-sparse-arrays
  const navBarArray = [
    {
      name: "Dashboard",
      path: "/agency/dashboard",
      navSVG: <DashboardIcon />,
    },
    {
      name: "Host",
      path: "/agency/host",
      navSVG: <HostIcon />,
      onClick: handelRemoveItem,
    },

    // {
    //   name: "User",
    //   path: "/agency/userTable",
    //   navSVG: <UserIcon />,
    //   onClick: handelRemoveItem,
    // },
    // {
    //   name: "Plan",
    //   navSVG: <Plan />,
    //   subMenu: [
    //     {
    //       subName: "Coin Plan",
    //       subPath: "/agency/plan",
    //     },
    //     {
    //       subName: "Vip Plan",
    //       subPath: "/agency/vipPlan",
    //     },
    //     {
    //       subName: "Purchased History",
    //       subPath: "/agency/planHistory",
    //     },
    //   ],
    // },
    // {
    //   name: "Flash Coin",
    //   path: "/agency/flashCoinTable",
    //   navSVG: <FlashCoin />,
    // },
    // {
    //   name: "Gift",
    //   navSVG: <Gift />,
    //   subMenu: [
    //     {
    //       subName: "Category",
    //       subPath: "/agency/category",
    //       onClick: handleGift,
    //     },
    //     {
    //       subName: "Gift",
    //       subPath: "/agency/gifts",
    //       onClick: handleGift,
    //     },
    //   ],
    // },
    {
      name: "Settlement",
      navSVG: <Settlement />,
      
      subMenu: [
        {
          subName: "All Settlement",
          subPath: "/agency/allSettlement",
        },
        {
          subName: "Solved",
          subPath: "/agency/settlementSolved",
        },
        {
          subName: "Pending",
          subPath: "/agency/settlementPending",
        },
      ],
    },
    {
      name: "Payouts",
      path: "/agency/payouts",
      navSVG: <Commission />,
    },
    {
      name: "Commission",
      path: "/agency/Commission",
      navSVG: <Commission />,
    },
    // {
    //   name: "Feedback",
    //   navSVG: <Feedback />,
    //   subMenu: [
    //     {
    //       subName: "Solved",
    //       subPath: "/agency/solvedFeedback",
    //     },
    //     {
    //       subName: "Pending",
    //       subPath: "/agency/pendingFeedback",
    //     },
    //   ],
    // },
  ];

  const supportArray = [
    {
      name: "Profile",
      navSVG: <Profile />,
      path: "/agency/agencyProfile",
    },
    // {
    //   name: "Setting",
    //   path: "/agency/settingPage",
    //   navSVG: <SettingIcon />,
    // },

    {
      name: "LogOut",
      // navIcon: "fa-solid fa-right-from-bracket",
      navSVG: <LogOut />,
      onClick: handleLogout,
    },
  ];

  const [totalPage, setTotalPage] = useState(9);

  return (
    <>
      <Script totalPage={totalPage} />
      <div className="mainSidebar">
        <div className="sideBar webSidebar">
          <Link to="/agency/dashBoard" >
            
            <div className="sideBarLogo boxCenter">
            <img
              src={Logo}
              alt=""
              width={"40px"}
              className="me-2"
              style={{ borderRadius: "20px" }}
            />
            <span className="fs-3 fw-bold text-white">Coca</span>
          </div>
            </Link>
          <hr className="mx-3 my-0" />

          {/* ======= Navigation ======= */}
          <div className="navigation menu">
            <p style={{ fontSize: "14px" }}>MENU </p>
            {/* <nav style={{ backgroundColor: "#f0f0f0" }}> */}
            <nav
              style={{
                background: "#191919",
                borderRadius: "10px",
                height: "495px",
                overflow: "auto",
              }}
            >
              {/* About */}
              {navBarArray?.length > 0 && (
                <>
                  {(totalPage > 0
                    ? navBarArray.slice(0, totalPage)
                    : navBarArray
                  ).map((res) => {
                    return (
                      <>
                        <Navigator
                          name={res?.name}
                          path={res?.path}
                          path2={res?.path2}
                          navIcon={res?.navIcon}
                          navIconImg={res?.navIconImg}
                          navSVG={res?.navSVG}
                          onClick={res?.onClick && res?.onClick}
                        >
                          {res?.subMenu &&
                            res?.subMenu?.map((subMenu) => {
                              return (
                                <Navigator
                                  subName={subMenu.subName}
                                  subPath={subMenu.subPath}
                                  subPath2={subMenu.subPath2}
                                  onClick={subMenu.onClick}
                                />
                              );
                            })}
                        </Navigator>
                      </>
                    );
                  })}
                </>
              )}
            </nav>
            {/* <div className="boxCenter mt-2">
              <DownArrow
                onClick={() => setTotalPage(navBarArray.length)}
                className={`text-center mx-auto cursor ${
                  totalPage === navBarArray.length && "d-none"
                }`}
                style={{ transition: "0.5s" }}
              />
            </div> */}
            <p className="mt-4 support" style={{ fontSize: "14px" }}>
              {" "}
              SUPPORT 
            </p>
            <div
              style={{
                background: "#191919",
                borderRadius: "10px",
              }}
            >
              {supportArray.map((res) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      navSVG={res?.navSVG}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu &&
                        res?.subMenu?.map((subMenu) => {
                          return (
                            <Navigator
                              subName={subMenu.subName}
                              subPath={subMenu.subPath}
                              subPath2={subMenu.subPath2}
                              onClick={subMenu.onClick}
                            />
                          );
                        })}
                    </Navigator>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

export const Script = (props) => {
  useEffect(() => {
    const handleClick = (event) => {
      const target = $(event.currentTarget);
      const submenu = target.next(".subMenu");

      $(".subMenu").not(submenu).slideUp();
      submenu.slideToggle();

      // Toggle rotation class on the clicked icon
      target.children("i").toggleClass("rotate90");

      // Remove rotation class from other icons
      $(".mainMenu > li > a > i")
        .not(target.children("i"))
        .removeClass("rotate90");
    };

    const handleToggle = () => {
      $(".mainNavbar").toggleClass("mobNav webNav");
      $(".sideBar").toggleClass("mobSidebar webSidebar");
      $(".mainagency").toggleClass("mobagency");
      $(".fa-angle-right").toggleClass("rotated toggleIcon");
    };

    $(".subMenu").hide();
    $(".mainMenu > li > a").on("click", handleClick);
    $(".navToggle").on("click", handleToggle);

    return () => {
      $(".mainMenu > li > a").off("click", handleClick);
      $(".navToggle").off("click", handleToggle);
    };
  }, [props.totalPage]);

  return null;
};
