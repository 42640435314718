import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./notification.type";

export const sendNotification = (formData) => (dispatch) => {
  axios
    .post(`admin/notification/SendNotificationToAgencyHost`, formData)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Notification sent successfully ");
        dispatch({
          type: ActionType.SEND_USER_NOTIFICATION,
          payload: { data: res.data.message },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const sendAllNotification = (formData) => (dispatch) => {
  ;

  axios
    .post(`admin/notification/SendNotificationToAgencyHost`, formData)
    .then((res) => {
      ;
      if (res.data.status) {
        dispatch({ type: ActionType.SEND_NOTIFICATION, payload: res.data });
        setToast("success", "Notification Sent Successfully");
      }
    })
    .catch((error) => setToast("error", error));
};

export const particularAgencySendNotification =
  (formData) => (dispatch) => {
    axios
      .post(`admin/notification/SendNotificationToAgencyHost`, formData)
      .then((res) => {
        if (res.data.status) {
          setToast("success", "Notification sent successfully ");
          dispatch({
            type: ActionType.SEND_AGENCY_NOTIFICATION,
            payload: { data: res.data.messag },
          });
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => setToast("toast", error));
  };
