import React, { useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getHostHistory } from "../../../store/host/host.action";

import moment from "moment";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import dayjs from "dayjs";
import male from "../../../../assets/images/boy.jpg";

const HostCallHistory = (props) => {
  const { history, total, totalCoin } = useSelector((state) => state.host);
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  const [startDate, setStartDate] = useState(
    state?.startDate ? state?.startDate : "ALL"
  );
  const [endDate, setEndDate] = useState(
    state?.endDate ? state?.endDate : "ALL"
  );

  // Retrieve the stored state string from localStorage
  const hostId = JSON.parse(localStorage.getItem("stateId"));


  useEffect(() => {
    dispatch(
      getHostHistory(
        props.props,
        "call",
        currentPage,
        rowsPerPage,
        startDate,
        endDate
      )
    );
  }, [dispatch, props, currentPage, rowsPerPage, startDate, endDate]);

  useEffect(() => {
    setData(history);
  }, [history]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   if (location?.state?.type === "custom") {
  //     if (type !== "live") {
  //       props.getHostHistory(
  //         name?._id,
  //         type,
  //         page,
  //         size,
  //         location?.state?.startDate,
  //         location?.state?.endDate
  //       );
  //     }
  //     setStartDate(location?.state?.startDate);
  //     setEndDate(location?.state?.endDate);
  //   } else {
  //     if (type !== "live") {
  //       props.getHostHistory(name?._id, type, page, size, startDate, endDate);
  //     }
  //   }
  // }, []);
  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  useEffect(() => {
    setData(history);
  }, [history]);

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };

  const navigate = useNavigate();

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      body: "details",
      Cell: ({ row }) => {
        var str = row?.userName;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            <span className="fw-semibold ">
              {row?.callType === "MissedCall" && (
                <i class="fa fa-phone m-1 text-danger"> </i>
              )}
              {row?.callType === "Outgoing" && (
                <i class="fa fa-phone m-1 text-success"></i>
              )}
              {row?.callType === "Incoming" && (
                <i class="fa fa-phone m-1 text-success"></i>
              )}
            </span>

            <span
              className="text-primary fw-bold fs-6"
              // onClick={() =>
              //   navigate("/admin/user/profile", { state: row?.userId })
              // }
              // style={{ cursor: "pointer" }}
            >
              @{str}
            </span>

            {/* <span className="me-2 fs-6 ">[{row?.callType}]</span> */}
          </>
        );
      },
    },
    
    {
      Header: "Type",
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold">
          {row?.callType === "MissedCall" && (
            <span className="text-danger">MissedCall</span>
          )}
          {row?.callType === "Outgoing" && (
            <span class="text-success"> Outgoing</span>
          )}
          {row?.callType === "Incoming" && (
            <span class="  text-success">Incoming</span>
          )}
        </span>
      ),
    },
    {
      Header: "Video Call Type",
      body: "videoCallType",
      class: "fw-semibold  fs-6",

      Cell: ({ row }) => (
        <span className="text-capitalized">{row.videoCallType ? row.videoCallType : "-"}</span>
      ),
    },
    {
      Header: "Duration",
      Cell: ({ row }) => (
        <span className="fw-bold fs-6">
          {row.callConnect
            ? row.callEndTime
              ? moment
                  .utc(
                    moment.duration(row?.duration, "seconds").as("milliseconds")
                  )
                  .format("HH:mm:ss")
              : " - "
            : "00:00:00"}
        </span>
      ),
    },

    {
      Header: "Coin",
      body: "hCoin",
      sorting: { type: "client" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.hCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.hCoin}</span>
        ),
    },
    { Header: "Description", body: "description", class: "fw-semibold fs-6" },
    {
      Header: "End By",
      body: "callEndReason",
      class: "fw-semibold  fs-6",

      Cell: ({ row }) => (
        <span>{row.callEndReason ? row.callEndReason : "-"}</span>
      ),
    },
    {
      Header: "Date",
      body: "date",
      sorting: { type: "client" },
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 0 ? date[0] : "N/A"}
          </span>
        );
        //  dayjs(row?.date).format("MM/DD/YYYY");
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 1 ? date[1] : "N/A"}
          </span>
        );
        // dayjs(row?.date).format("HH:mm:ss");
      },
    },

    // add more columns as needed
  ];

  return (
    <>
      <div className="row d-flex align-items-center  py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory tableMain border-top">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
          />
        </div>
        <Pagination
          component="div"
          count={history?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={total}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default connect(null, { getHostHistory })(HostCallHistory);
