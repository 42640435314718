import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkf_LRug6OMOCoiUOPl2WDMU3Fpj09gds",
  authDomain: "pepsi-dcc36.firebaseapp.com",
  projectId: "pepsi-dcc36",
  storageBucket: "pepsi-dcc36.appspot.com",
  messagingSenderId: "948363261523",
  appId: "1:948363261523:web:f07a6ad3c93d7cfbbf78a6"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const publicKey =
  "BKwHA_MLG3VWPBhe-pYF5g8Bu2CIvBf8bNd7_ch77qiCGPm_yNPOm6NvJUpYlvwFNdRMwrz091Gt8KDRPZJj5P4";
  
export const requestForToken = () => {

  return getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("FCMToken", JSON.stringify(currentToken));
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });



