import { useEffect, useState } from "react";
import Button from "../extra/Button";
import Input from "../extra/Input";
import Logo from "../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { loginAgency } from "../store/agency/agency.action";
import { connect, useSelector } from "react-redux";
import Logo1 from "../../assets/images/logo1.png";
import { requestForToken } from "../util/Firebase";
import axios from "axios";
import { apiInstanceFetch } from "../util/api";

const Login = (props) => {
  let navigate = useNavigate();

  const isAuth = useSelector((state) => state.agency?.isAuth);

  useEffect(() => {
    isAuth && navigate("/agency");
  }, [isAuth]);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [types, setTypes] = useState("password");
  const [ip, setIp] = useState("");

  const [error, setError] = useState({
    code: "",
    password: "",
  });

  useEffect(() => {
    requestForToken();
  }, []);

  //Get Country API
  useEffect(() => {
    apiInstanceFetch
      .get(`http://ip-api.com/json`)
      .then((res) => {
        setIp(res?.query);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSubmit = () => {
    if (!code || !password) {
      let error = {};
      if (!code) error.code = "Code Is Required !";
      if (!password) error.password = "password is required !";
      return setError({ ...error });
    } else {
      let fcm_token = JSON.parse(localStorage.getItem("FCMToken"));

      let login = {
        code,
        password,
        fcm_token,
        identity: ip,
      };
      props.loginAgency(login);
    }
  };
  const hideShow = () => {
    types === "password" ? setTypes("text") : setTypes("password");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="mainLoginPage position-relative">
        {/* <img src={Wall1} className="loginImage"/> */}
        <div className="loginDiv">
          <div className="logoBar d-flex align-items-center ">
            <div className="loginLogo boxCenter me-3 pt-1 pe-1">
              <img
                src={Logo1}
                alt=""
                width={"100px"}
                style={{
                  borderRadius: "50%",
                  marginLeft: "4px",
                  boxShadow: "0 0 10px ",
                }}
              />
            </div>
            <div className="logoText text-center">
              <h3 class="">Welcome To</h3>
              <h2 className="fw-bold   mb-0">Coca</h2>
            </div>
          </div>
          <div className="loginPage pt-3">
            <div className="loginInput mb-5">
              <div className="prime-input ">
                <label htmlFor="loginEmail">Code</label>
                <div className="d-flex">
                  <Button
                    type="button"
                    btnIcon={`fas fa-duotone fa-code`}
                    newClass={`themeBtn text-center fs-6 bgDark p-0 text-white btn-primary loginIcons`}
                  />
                  <input
                    type={`number`}
                    className="form-input loginFields"
                    id={code}
                    value={code}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                      setCode(parseInt(e.target.value));
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          code: `Code Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          code: "",
                        });
                      }
                    }}
                  />
                </div>
                {error.code && <span className="text-red">{error.code}</span>}
              </div>

              <div className="prime-input ">
                <label htmlFor="loginEmail">Password</label>
                <div className="d-flex">
                  <Button
                    type="button"
                    btnIcon={`fa-solid fa-lock`}
                    newClass={`themeBtn text-center fs-6 bgDark p-0 text-white loginIcons`}
                  />
                  <input
                    type={types}
                    className="loginFields form-input "
                    id={password}
                    onKeyPress={handleKeyPress}
                    value={password}
                    // onWheel={(e) => type === "number" && e.target.blur()}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          password: `Password Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          password: "",
                        });
                      }
                    }}

                    // errorMessage={errorMessage}
                    // onKeyPress={onKeyPress}
                  />
                  <div className="passHideShow" onClick={hideShow}>
                    {types === "password" ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </div>
                </div>
                {error.password && (
                  <span className="text-red">{error.password}</span>
                )}
              </div>
            </div>
            {/* <div className="forgetPassword themeFont primeBlackColor my-4">
              Forgot password?
            </div> */}
            <div className="loginButton mt-4">
              <Button
                newClass={`text-white`}
                btnColor={`blackPrime`}
                btnName={`Sign In`}
                onClick={handleSubmit}
                style={{ borderRadius: "6px", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { loginAgency })(Login);
