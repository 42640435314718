import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getHostHistory } from "../../../store/host/host.action";
import moment from "moment";
import $ from "jquery";
import male from "../../../../assets/images/male.jpg";

import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../../extra/Pagination";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Analytics from "../../../extra/Analytics";

const HostGiftHistory = (props) => {
  const { history, total, totalCoin } = useSelector((state) => state.host);
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [type, setType] = useState("0");

  const { state } = useLocation();
  const [startDate, setStartDate] = useState(state ? state?.startDate : "ALL");
  const [endDate, setEndDate] = useState(state ? state?.endDate : "ALL");
  const hostId = JSON.parse(localStorage.getItem("stateId"));

  let propValue;
  if (state) {
    if (state.type === "agency") {
      propValue = dialogueData?._id;
    } else {
      propValue = state?.id;
    }
  } else {
    propValue = dialogueData?._id;
  }
  useEffect(() => {
    dispatch(
      getHostHistory(
        propValue ? propValue : hostId?.id,
        "gift",
        currentPage,
        rowsPerPage,
        startDate,
        endDate
      )
    );
  }, [dispatch, propValue, currentPage, type, rowsPerPage, startDate, endDate]);

  useEffect(() => {
    setData(history);
  }, [history]);

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event), 20);
    setCurrentPage(1);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      body: "detalis",
      Cell: ({ row }) => {
        var str = row?.userName;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            <span
              className="text-primary fw-bold fs-6"
              // onClick={() => navigate("/admin/user/profile", { state: row })}
            >
              @{str}
            </span>

            {/* <span className="me-2 fs-6 ">[{row?.callType}]</span> */}
          </>
        );
      },
    },

    {
      Header: "Coin",
      body: "hCoin",
      sorting: { type: "client" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.hCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.hCoin}</span>
        ),
    },

    {
      Header: "Date",
      body: "date",
      sorting: { type: "client" },
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 0 ? date[0] : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 1 ? date[1] : "N/A"}
          </span>
        );
      },
    },

    // add more columns as needed
  ];

  return (
    <>
      <div className="row d-flex align-items-center py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory tableMain border-top ">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
          />
        </div>
        <Pagination
          component="div"
          count={history?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={total}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};
export default connect(null, { getHostHistory })(HostGiftHistory);
