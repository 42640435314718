import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAgencyCommision } from "../../store/payouts/payouts.action";
import Table from "../../extra/Table";
import Title from "../../extra/Title";

const Commission = () => {
  const { commission } = useSelector((state) => state.payouts);
  const agency = useSelector((state) => state?.agency?.agency);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAgencyCommision(agency?._id));
  }, [agency]);

  useEffect(() => {
    setData(commission?.commission);
  }, [commission]);

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Upper Coin",
      body: "upperCoin",
      sorting: { type: "client" },
    },
    {
      Header: "Percentage",
      Cell: ({ row }) => <span>{row?.percentage + " %"}</span>,
    },
  ];
  return (
    <div>
      <div className="settlementTable  position-relative">
        <div className="settlementHeader primeHeader boxBetween">
          <Title name={`Agency Commission`} />
        </div>
        <div className="d-flex justify-content-between mx-3 mt-4">
          <h5>
            <span className="text-primary text-capitalize">
              {commission?.name}
            </span>
            ' Agency
          </h5>
        </div>
        <div className="primeHeader mt-4 row">
          <div className="col-md-6 p-0 d-flex"></div>

          <div
            className="d-flex justify-content-end col-md-6 m-0 p-0 col-sm-12"
            style={{ alignSelf: "center" }}
          ></div>
        </div>

        <div className="userMain">
          <div className="tableMain mt-3">
            <Table data={data} mapData={mapData} type={"client"} />
          </div>
        </div>

        <div className="userFooter primeFooter"></div>
      </div>
    </div>
  );
};

export default Commission;
