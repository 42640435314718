import axios from "axios";
import * as ActionType from "./Payouts.type";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getPayouts =
  (agencyId, startDate, endDate, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/agencySettlement/getAllAgencySettlemtforPayOuts?agencyId=${agencyId}&startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GATE_AGENCY_PAYOUTS,
            payload: { data: res.history, total: res.total },
          });
        } else {
          setToast("error", res.message);
        }
      })
      .catch((error) => {
        setToast(error.message);
      });
  };

export const getAgencyCommision = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/agency/profile/${id}`)
    .then((res) => {
      
      dispatch({
        type: ActionType.GET_COMMISSION,
        payload: res.agency,
      });
    })
    .catch((error) => console.log(error));
};
