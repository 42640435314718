import * as ActionType from "./dashboard.type";

const initialState = {
  dashBoard: {},
  revenueChart:[],
  hostChart:[]
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
 

      case ActionType.GET_DASHBOARD: 
        return {
          ...state,
          dashBoard: action.payload.data,
        };
     
      case ActionType.GET_HOST_CHART: 
      

      return {
        ...state,
        hostChart: action.payload?.data,
      };
      case ActionType.GET_SETTLEMENT_CHART: 
      return {
        ...state,
        revenueChart: action.payload.data,
      };
    default:
      return state;
  }
};
